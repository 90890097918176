<template>
  <div class="cardUserManageEdit">
    <div class="buttonbc">
      <el-button @click="() => {
        $router.go(-1);
      }
        " size="small" class="back_btn">
        <img src="@/assets/back.png" alt="" />
      </el-button>
    </div>
    <el-form class="form" style="clear: both" ref="sizeForm" :model="sizeForm" :rules="sizeFormRules"
      label-width="120px">
      <el-form-item prop="name" label="人员名称">
        <el-input placeholder="请输入人员名称" maxlength="50" style="width: 200px" class="ipt" v-model.trim="sizeForm.name"
          clearable></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="手机号码">
        <el-input placeholder="请输入手机号码" style="width: 200px" class="ipt" v-model.trim="sizeForm.mobile" maxlength="11"
          clearable></el-input>
      </el-form-item>
      <el-form-item label="人员角色">
        <el-radio-group v-model="sizeForm.role_type" @input="changeRoleType">
          <el-radio :label="1">销售人员</el-radio>
          <el-radio :label="2">客户</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="sizeForm.role_type == 1 ? '选择销售' : '选择客户'"
        prop="open_card_personnel_customer_salesman_rel_dolist">
        <el-button size="mini" @click="newDistribute(sizeForm.role_type)" type="primary">{{ sizeForm.role_type == 1 ?
        '选择销售人员' :
        '选择客户' }}</el-button>
        <el-table :data="tableData" tooltip-effect="dark" border
          :style="{ width: sizeForm.role_type == 1 ? '50%' : '100%' }">
          <el-table-column label="编号" :prop="sizeForm.role_type == 1 ? 'sn' : 'no'" min-width="130"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 1" prop="name" label="销售人员名称" min-width="150"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 1" prop="mobile" label="手机号" width="110" show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 2" prop="name" label="客户名称" min-width="150"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 2" width="110" prop="contacts_person" label="联系人名称"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 2" prop="contacts_phone" label="联系电话" show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 2" key="sell_name" prop="sell_name" label="所属销售人员"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column v-if="sizeForm.role_type == 2" key="account_period" width="80" prop="account_period"
            label="账期类型" show-overflow-tooltip>
            <template slot-scope="{row}">{{ row.account_period ? '有账期' : '无账期' }}</template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="text" style="color: red;" size="small"
                @click="deleteDistribute(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
        <el-button style="margin:40px  0 85px 0" @click="handleSave" size="mini" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
    <!-- 添加销售人员 -->
    <cardcheck ref="cardCheck" :parentData="tableData" @selectCardCheck="selectCardCheck"></cardcheck>
    <!-- 添加客户 -->
    <cardAccount ref="cardAccount" :parentData="tableData" @selectCardCheck="selectCardCheck"></cardAccount>
  </div>
</template>

<script>
import cardcheck from "./components/cardCheck";
import cardAccount from "./components/cardAccount";
import * as API_Users from "@/api/users";
import { RegExp } from "@/../ui-utils";
export default {
  components: { cardcheck, cardAccount },
  data () {
    return {
      sizeForm: {
        role_type: 1,
        open_card_personnel_customer_salesman_rel_dolist: []
      },
      sizeFormRules: {
        name: [
          {
            required: true,
            message: '请填写开卡人员名称',
            trigger: 'blur'
          },
        ],
        mobile: [
          { required: true, message: '请填写手机号码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请填写手机号码'));
              } else if (!RegExp.mobile.test(value)) {
                callback(new Error('请输入正确的手机号'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        open_card_personnel_customer_salesman_rel_dolist: [
          { required: true, message: '请选择人员', trigger: 'blur' },
        ]
      },
      tableData: []
    };
  },
  created () {
    if (this.$route.params.id) {
      this.sizeForm.id = this.$route.params.id;
      this.getDetail();
    }
  },
  methods: {
    /**查询详情 */
    getDetail () {
      API_Users.getMenberOpenDetail(this.sizeForm.id).then(res => {
        const { role_type, mobile, name, open_card_personnel_customer_salesman_rel_dolist, id } = res;
        this.sizeForm = {
          role_type, mobile, name, open_card_personnel_customer_salesman_rel_dolist, id
        };
        if (res.role_type == 1) {//销售人员
          this.tableData = res.salesman_dolist;
        } else { //客户
          this.tableData = res.customer_dolist;
        }
        this.sizeForm.open_card_personnel_customer_salesman_rel_dolist = this.tableData.map((item, index) => {
          let obj = {};
          return obj = {
            relation_id: item.id,
            role_type: this.sizeForm.role_type
          }
        })
      });
    },
    newDistribute (type) {
      if (type == 1) {
        //添加销售人员
        this.$refs.cardCheck.show();
      } else {
        //添加客户
        this.$refs.cardAccount.show();
      }
    },
    /**人员角色切换 */
    changeRoleType () {
      this.sizeForm.open_card_personnel_customer_salesman_rel_dolist = [];
      this.tableData = []
    },
    /**保存 */
    handleSave () {
      this.$refs.sizeForm.validate((valid) => {
        if (valid) {
          if (this.sizeForm.id) {
            API_Users.editMemberOpen(this.sizeForm).then(res => {
              this.$message.success("保存成功");
              this.$router.back();
            });
          } else {
            API_Users.addMemberOpen(this.sizeForm).then(res => {
              this.$message.success("保存成功");
              this.$router.back();
            });
          }
        }
      });
    },
    /**挑选的数据 */
    selectCardCheck (data) {
      data.forEach(item => {
        this.tableData.unshift(item)
      })
      this.sizeForm.open_card_personnel_customer_salesman_rel_dolist = this.tableData.map((item, index) => {
        let obj = {};
        return obj = {
          relation_id: item.id,
          role_type: this.sizeForm.role_type
        }
      })
    },
    /**删除表格行 */
    deleteDistribute (index, row) {
      this.tableData.splice(index, 1);
      this.sizeForm.open_card_personnel_customer_salesman_rel_dolist.splice(index, 1)
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table th {
  background: #e6ecf7;
  color: #333;
  font-weight: 600;
  line-height: 1;
  padding: 8px 0;
}

.cardUserManageEdit {
  background-color: #fff;

  .kj {
    height: 330px;
    overflow: auto;
  }

  .goods-category dl {
    width: 30%;
    display: inline-block;
    margin: 0 4% 4% 0;
    border: 1px solid rgba(230, 236, 247, 1);
    vertical-align: top;
    border-radius: 5px;
    text-align: center;
  }

  .goods-category dd {
    margin-inline-start: 0;
    line-height: 40px;
    border-top: 1px solid #efefef;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
  }

  .goods-category dd.activeClass {
    color: #666666;
    background: rgba(238, 240, 245, 1);
  }

  .goods-category dt {
    font-size: 15px;
    color: white;
    border-radius: 5px 5px 0 0;
    line-height: 40px;
    background: rgba(26, 67, 169, 1);
  }
}

.buttonbc {
  float: right;
  height: 40px;
  margin: 10px;

  .back_btn {
    width: 36px;
    height: 36px;
    background-color: #1a43a9;
    border-radius: 50%;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

form {
  .el-form-item {
    margin-bottom: 15px;
  }

  .el-radio {
    margin-bottom: 0;
  }


}
</style>
