<template>
  <x-dialog :proxy="couponsDialog">
    <en-table-layout ref="enTableLayout" :tableData="tableData.data" @selection-change="selectChange"
      :row-key="(row) => row.id">
      <template slot="toolbar">
        <el-form-item>
          <el-input style="width: 320px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 140px;">
              <el-option label="销售人员名称" value="name"></el-option>
              <el-option label="手机号" value="mobile"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button native-type="submit" @click.stop.prevent="search" size="small" type="primary">
            搜索
          </el-button>
          <el-button @click="handleCardCheck" size="small" type="primary">
            批量挑选
          </el-button>
        </el-form-item>
      </template>

      <template slot="table-columns">
        <el-table-column :key="'selection'" :reserve-selection="true" type="selection" width="50" fixed="left" />
        <el-table-column label="编号" show-overflow-tooltip fixed="left">
          <template slot-scope="scope">{{ scope.row.sn }}</template>
        </el-table-column>
        <el-table-column prop="name" label="销售人员名称" show-overflow-tooltip fixed="left" />
        <el-table-column prop="mobile" label="手机号" show-overflow-tooltip />
        <el-table-column prop="create_time" label="创建时间" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.data_total" />
    </en-table-layout>
  </x-dialog>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_Sales from '@/api/sales';
export default {
  name: 'settingcoupon',
  components: {
    XDialog,
    EnTableLayout
  },
  props: {
    parentData: {
      type: Array,
      default: () => {
        return []
      }
    }

  },
  data () {
    return {
      //选中得勾选项
      selectCarmiTable: [],
      key_word: 'name',
      params: {
        keyWord: '',
        mobile: "",
        name: "",
        page_size: 20,
        page_no: 1,
        status: 1,//启用中
      },
      tableData: {
        data: [],
        total: 0
      },
      couponsDialog: $xDialog.create({
        title: "选择销售人员",
        width: "65vw",
        disableCancel: true,
        disableConfirm: true,
        wrapperClass: "cash-coupon-manager-main-dialog"
      }),
    }
  },
  created () {
    this.getTableList()
  },
  methods: {
    show (row) {
      this.getTableList()
      this.couponsDialog.display();
    },
    /**复选框选中事件 */
    selectChange (val) {
      this.selectCarmiTable = val;
    },
    //批量挑选操作
    handleCardCheck () {
      let arr = [];
      if (
        this.selectCarmiTable &&
        this.selectCarmiTable.length &&
        this.selectCarmiTable.length > 0
      ) {
        this.selectCarmiTable.map((item) => {
          arr.push(item.id);
        });
      } else {
        this.$message.error("请勾选数据");
        return;
      }
      this.$emit("selectCardCheck", this.selectCarmiTable);
      this.couponsDialog.dismiss();
    },
    search () {
      this.getTableList(1)
    },
    /**查询销售人员列表 */
    getTableList (page_no) {
      let params = {
        ...this.params
      }
      page_no && (params.page_no = 1);
      params[this.key_word] = params.keyWord;
      if (this.parentData.length > 0) {
        const arrList = this.parentData.map(item => item.id);
        params.ids = arrList.join()
      }
      delete params.keyWord;
      API_Sales.getSalesMan(params).then((res) => {
        this.tableData = res;

      });
    },
    /**勾选父组件传递的数据 */
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.enTableLayout.$refs.table.toggleRowSelection(row);
        });
      }
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getTableList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getTableList();
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-scrollbar__bar.is-vertical {
  width: 0;
}
</style>